import React from "react";

const ConsentModal = ({ show, onClose, onConsent }) => {
  return (
    // Modal container, conditionally render based on 'show'
    <div className={`modal ${show ? "is-active" : ""}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content">
        <div className="box">
          <h1 className="title">Consent to Share Information</h1>
          <p>
            We would like to share your details with our partner brands. Do you
            consent to this?
          </p>
          <div className="buttons is-right mt-5">
            <button className="button is-danger" onClick={onClose}>
              Decline
            </button>
            <button className="button is-primary" onClick={onConsent}>
              Accept
            </button>
          </div>
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={onClose}
      ></button>
    </div>
  );
};

export default ConsentModal;
