import React from "react";
import { useNavigate } from "react-router-dom";
// import 'bulma/css/bulma.min.css'; // Import Bulma CSS
import { Config } from "../.env.js";
const Instagram = () => {
  const navigate = useNavigate();
  const handleLogin = () => {
    window.location.href = `${Config.API_URL}/auth/facebook`;
  };

  return (
    <div className="container">
      <div className="section">
        <div className="columns is-centered">
          <div className="column is-half">
            <button
              className="button is-primary is-fullwidth"
              onClick={handleLogin}
            >
              Influencer Login with Facebook
            </button>
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-half">
            <button
              className="button is-primary is-fullwidth"
              onClick={() => navigate("/brand-login")}
            >
              Brand Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instagram;
