import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "bulma/css/bulma.min.css";
import EngagementChart from "./components/EngagementChart";
import DemographicPieChart from "./components/DemographicPieChart";
import AgeRangeLineChart from "./components/AgeRangeLineChart";
import CountryCodeBarChart from "./components/CountryCodeBarChart";
import CityDemographicBarChart from "./components/CityDemographicBarChart";
import AudienceDataCharts from "./components/AudienceDataCharts";
import Logout from "./components/Logout";
import { NavLink } from "react-router-dom";
import "../css/UserDetailPage.css";
import { Config } from "../.env.js";
import ConsentModal from "./components/ConsentModal";
// import EngagementChart1 from './components/EngegmentRateText';

const UserDetailPage = () => {
  const { instagramAccountId } = useParams();
  const [userData, setUserData] = useState(null);
  const [engagementData, setEngagementData] = useState(null);
  const [mediaData, setMediaData] = useState([]);
  const [demographicData, setDemographicData] = useState([]);
  const [ageRangeData, setAgeRangeData] = useState([]);
  const [countryCodeData, setCountryCodeData] = useState([]);
  const [cityDemographicData, setCityDemographicData] = useState([]);
  const [activeSection, setActiveSection] = useState(null);
  const [showEngagementChart, setShowEngagementChart] = useState(false);
  const [activeMediaId, setActiveMediaId] = useState(null);
  const [showConsentModal, setShowConsentModal] = useState(false);

  // Function to handle comments icon click
  const handleCommentClick = (mediaId) => {
    setActiveMediaId((prevId) => (prevId === mediaId ? null : mediaId));
  };

  const handleCardClick = () => {
    setShowEngagementChart((prevState) => !prevState);
  };

  const handleConsent = async () => {
    try {
      await axios.post(`${Config.API_URL}/api/consent`, {
        instagramAccountId,
        consent: true,
      });

      setShowConsentModal(false);
    } catch (error) {
      console.error("Error saving consent:", error);
    }
  };

  const handleDecline = async () => {
    try {
      await axios.post(`${Config.API_URL}/api/consent`, {
        instagramAccountId,
        consent: false,
      });

      setShowConsentModal(false);
    } catch (error) {
      console.error("Error saving consent:", error);
    }
  };

  useEffect(() => {
    // Fetch user details and data
    // axios
    //   .get(`${Config.API_URL}/api/data/${instagramAccountId}`)
    //   .then((response) => setUserData(response.data))
    //   .catch((error) => console.error("Error fetching user details:", error));
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${Config.API_URL}/api/data/${instagramAccountId}`
        );
        setUserData(response.data);
        // console.log("Consent:", response.data.consent);
        // Check if consent is null and show the modal
        if (response.data.consent === null) {
          setShowConsentModal(true);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserData();

    axios
      .get(
        `${Config.API_URL}/api/engagements?instagramAccountId=${instagramAccountId}`
      )
      .then((response) => setEngagementData(response.data.data))
      .catch((error) =>
        console.error("Error fetching engagement data:", error)
      );

    axios
      .get(`${Config.API_URL}/api/followers-age-range/${instagramAccountId}`)
      .then((response) => setAgeRangeData(response.data))
      .catch((error) =>
        console.error("Error fetching followers age range data:", error)
      );
  }, [instagramAccountId]);

  const fetchMediaData = () => {
    axios
      .get(`${Config.API_URL}/api/media/${instagramAccountId}`)
      .then((response) => setMediaData(response.data))
      .catch((error) => console.error("Error fetching media data:", error));
  };

  const fetchDemographicData = () => {
    axios
      .get(`${Config.API_URL}/api/follower-demographics/${instagramAccountId}`)
      .then((response) => setDemographicData(response.data))
      .catch((error) =>
        console.error("Error fetching demographic data:", error)
      );
  };

  const fetchCountryCodeData = () => {
    axios
      .get(`${Config.API_URL}/api/country-codes/${instagramAccountId}`)
      .then((response) => {
        const countryCodes = response?.data?.[0]?.totalValue;
        setCountryCodeData(countryCodes);
      })
      .catch((error) =>
        console.error("Error fetching country code data:", error)
      );
  };

  const fetchCityDemographicData = () => {
    axios
      .get(`${Config.API_URL}/api/city-demographics/${instagramAccountId}`)
      .then((response) => {
        if (response.data.length > 0) {
          const citiesData = response.data[0].cities;
          setCityDemographicData(citiesData);
        }
      })
      .catch((error) =>
        console.error("Error fetching city demographic data:", error)
      );
  };

  const handleButtonClick = (section) => {
    setShowEngagementChart(false);
    if (section === activeSection) {
      setActiveSection(null);
    } else {
      setActiveSection(section);
      if (section === "Profile") {
        axios
          .get(`${Config.API_URL}/api/data/${instagramAccountId}`)
          .then((response) => setUserData(response.data))
          .catch((error) =>
            console.error("Error fetching user details:", error)
          );
      } else if (section === "Medias") {
        fetchMediaData();
      } else if (section === "Followers_Demographic") {
        fetchDemographicData();
      } else if (section === "Followers_Age_Range") {
        axios
          .get(
            `${Config.API_URL}/api/followers-age-range/${instagramAccountId}`
          )
          .then((response) => setAgeRangeData(response.data))
          .catch((error) =>
            console.error("Error fetching followers age range data:", error)
          );
      } else if (section === "Country_Code") {
        fetchCountryCodeData();
      } else if (section === "City_Demographic") {
        fetchCityDemographicData();
      } else if (section === "Audience_Data") {
      } else if (section === "metrics_data") {
        axios
          .get(`${Config.API_URL}/api/data/${instagramAccountId}`)
          .then((response) => setUserData(response.data))
          .catch((error) =>
            console.error("Error fetching user details:", error)
          );
      }
    }
  };

  if (!userData || !engagementData) {
    return <div className="container">Loading...</div>;
  }

  // Calculate engagement rate
  const totalEngagement = engagementData.reduce(
    (total, item) => total + item.engagementRate,
    0
  );
  const averageEngagementRate =
    engagementData.length > 0 ? totalEngagement / engagementData.length : 0;

  return (
    <>
      <div className="container">
        <div
          className="card profile-card"
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "20px",
            marginBottom: "20px",
            borderRadius: "30px",
          }}
        >
          <figure className="image is-128x128" style={{ marginRight: "20px" }}>
            <img
              src={userData.profilePictureUrl || "default-profile.png"}
              alt={userData.username}
            />
          </figure>
          <div className="content">
            <div className="content">
              <h2 className="title is-4">@{userData.username}</h2>
              <p className="subtitle is-6">{userData.category}</p>
              <p className="subtitle is-6">{userData.biography}</p>
              {/* <p className="subtitle is-6">{userData.followersCount} Followers</p>
            <p className="subtitle is-6">{userData.followsCount} Following</p> */}
              {/* <p className="subtitle is-6">Website: <a href={userData.website} target="_blank" rel="noopener noreferrer">{userData.website}</a></p> */}
            </div>
          </div>
          <div className="column">
            <div
              className="columns is-multiline is-vcentered"
              style={{ marginRight: "-100px" }}
            >
              {/* Engagement Rate Display */}
              <div className="column is-half">
                <div
                  className="content-box"
                  style={{
                    width: "200px",
                    height: "100px",
                    padding: "20px",
                    border: "1px solid #ddd",
                    borderRadius: "30px",
                    backgroundColor: "#f9f9f9",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "130px",
                  }}
                >
                  <div className="content has-text-centered">
                    <span className="icon is-large">
                      <i className="fas fa-chart-line fa-2x"></i>
                    </span>
                    <h4 className="title is-6">Engagement Rate</h4>
                    <p className="subtitle is-5">
                      {averageEngagementRate.toFixed(2)}%
                    </p>
                  </div>
                </div>
              </div>

              {/* Followers Count Display */}
              <div className="column is-half">
                <div
                  className="content-box"
                  style={{
                    width: "200px",
                    height: "100px",
                    padding: "20px",
                    border: "1px solid #ddd",
                    borderRadius: "30px",
                    backgroundColor: "#f9f9f9",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="content has-text-centered">
                    <span className="icon is-large">
                      <i className="fas fa-users fa-2x"></i>
                    </span>
                    <h4 className="title is-6">Followers</h4>
                    <p className="subtitle is-5">{userData.followersCount}</p>
                  </div>
                </div>
              </div>

              {/* Following Count Display */}
              <div className="column is-full">
                <div
                  className="content-box"
                  style={{
                    width: "200px",
                    height: "100px",
                    padding: "20px",
                    border: "1px solid #ddd",
                    borderRadius: "30px",
                    backgroundColor: "#f9f9f9",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "250px",
                  }}
                >
                  <div className="content has-text-centered">
                    <span className="icon is-large">
                      <i className="fas fa-user-friends fa-2x"></i>
                    </span>
                    <h4 className="title is-6">Following</h4>
                    <p className="subtitle is-5">{userData.followsCount}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card" style={{ borderRadius: "30px" }}>
          <div className="card-content">
            <div className="card-nav-links">
              <NavLink
                to="#profile"
                className={activeSection === "Profile" ? "is-selected" : ""}
                onClick={() => handleButtonClick("Profile")}
              >
                Profile
              </NavLink>
              <NavLink
                to="#metrics_data"
                className={
                  activeSection === "metrics_data" ? "is-selected" : ""
                }
                onClick={() => handleButtonClick("metrics_data")}
              >
                Key Metrics
              </NavLink>
              <NavLink
                to="#medias"
                className={activeSection === "Medias" ? "is-selected" : ""}
                onClick={() => handleButtonClick("Medias")}
              >
                Medias
              </NavLink>
              {/* <NavLink
              to="#followers-demographic"
              className={activeSection === 'Followers_Demographic' ? 'is-selected' : ''}
              onClick={() => handleButtonClick('Followers_Demographic')}
            >
              Followers Demographic
            </NavLink> */}
              <NavLink
                to="#followers-age-range"
                className={
                  activeSection === "Followers_Age_Range" ? "is-selected" : ""
                }
                onClick={() => handleButtonClick("Followers_Age_Range")}
              >
                Followers Age Range
              </NavLink>
              <NavLink
                to="#country-code"
                className={
                  activeSection === "Country_Code" ? "is-selected" : ""
                }
                onClick={() => handleButtonClick("Country_Code")}
              >
                Country Code
              </NavLink>
              <NavLink
                to="#city-demographic"
                className={
                  activeSection === "City_Demographic" ? "is-selected" : ""
                }
                onClick={() => handleButtonClick("City_Demographic")}
              >
                City Demographic
              </NavLink>

              <NavLink
                to="#audience"
                className={
                  activeSection === "Audience_Data" ? "is-selected" : ""
                }
                onClick={() => handleButtonClick("Audience_Data")}
              >
                Audience Data
              </NavLink>
            </div>
          </div>
        </div>

        {activeSection === "Profile" && userData && (
          <div className="section">
            <h4 className="title is-6">Profile Details</h4>
            <div className="content">
              <p>
                <strong>Full Name:</strong> {userData.name}
              </p>
              <p>
                <strong>Biography:</strong> {userData.biography}
              </p>
              <p>
                <strong>Category:</strong> {userData.category}
              </p>
              <p>
                <strong>Profile Picture URL:</strong>{" "}
                <a
                  href={userData.profilePictureUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Profile Picture
                </a>
              </p>
              <p>
                <strong>Profile URL:</strong>{" "}
                <a
                  href={userData.profileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {userData.profileUrl}
                </a>
              </p>
              <p>
                <strong>Media Count:</strong> {userData.mediaCount}
              </p>
              <p>
                <strong>Followers:</strong> {userData.followersCount}
              </p>
              <p>
                <strong>Following:</strong> {userData.followsCount}
              </p>
              <p>
                <strong>Website:</strong>{" "}
                <a
                  href={userData.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {userData.website}
                </a>
              </p>
            </div>
          </div>
        )}

        {activeSection === "metrics_data" && (
          <div>
            <h3 className="title is-5">KEY METRICS</h3>
            <div className="column">
              <div className="columns">
                {/* First Row: Engagement Rate and Total Posts */}
                <div className="column is-half">
                  <div
                    className="card card-move-up is-flex is-justify-content-center is-align-items-center"
                    style={{
                      height: "100px",
                      marginBottom: "20px",
                      cursor: "pointer",
                      borderRadius: "30px",
                      backgroundColor: "#d9cbc9",
                    }}
                    onClick={handleCardClick}
                  >
                    <div className="card-content has-text-centered">
                      <span className="icon is-large">
                        <i className="fas fa-chart-line fa-2x"></i>
                      </span>
                      <h4 className="title is-6">Engagement Rate</h4>
                      <p className="subtitle is-5">
                        {averageEngagementRate.toFixed(2)}%
                      </p>
                    </div>
                  </div>
                </div>
                {!showEngagementChart && (
                  <>
                    <div className="column is-half">
                      <div
                        className="card card-move-up is-flex is-justify-content-center is-align-items-center"
                        style={{
                          height: "100px",
                          marginBottom: "20px",
                          cursor: "pointer",
                          borderRadius: "30px",
                          backgroundColor: "#d9cbc9",
                        }}
                        onClick={() => handleButtonClick("Medias")}
                      >
                        <div className="card-content has-text-centered">
                          <span className="icon is-large">
                            <i className="fas fa-images fa-2x"></i>
                          </span>
                          <h4 className="title is-6">Total Posts</h4>
                          <p className="subtitle is-5">{userData.mediaCount}</p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {!showEngagementChart && (
                <>
                  <div className="columns">
                    {/* Second Row: Followers and Following */}
                    <div className="column is-half">
                      <div
                        className="card card-move-up is-flex is-justify-content-center is-align-items-center"
                        style={{
                          height: "100px",
                          marginBottom: "20px",
                          borderRadius: "30px",
                          backgroundColor: "#d9cbc9",
                        }}
                      >
                        <div className="card-content has-text-centered">
                          <span className="icon is-large">
                            <i className="fas fa-user-friends fa-2x"></i>
                          </span>
                          <h4 className="title is-6">Followers</h4>
                          <p className="subtitle is-5">
                            {userData.followersCount}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="column is-half">
                      <div
                        className="card card-move-up is-flex is-justify-content-center is-align-items-center"
                        style={{
                          height: "100px",
                          marginBottom: "20px",
                          borderRadius: "30px",
                          backgroundColor: "#d9cbc9",
                        }}
                      >
                        <div className="card-content has-text-centered">
                          <span className="icon is-large">
                            <i className="fas fa-user-check fa-2x"></i>
                          </span>
                          <h4 className="title is-6">Following</h4>
                          <p className="subtitle is-5">
                            {userData.followsCount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {showEngagementChart && (
          <div
            className="column is-half"
            style={{ marginLeft: "600px", marginTop: "-200px" }}
          >
            <div className="card">
              <div className="card-content">
                <h3 className="title is-5">Engagement Rate Chart</h3>
                <EngagementChart data={engagementData} />
              </div>
            </div>
          </div>
        )}
        {/* {
        activeSection === 'Medias' && (
          <div className="section">
            <h4 className="title is-6">Media Section</h4>
            {mediaData.length > 0 ? (
              mediaData.map(media => (
                <div key={media.id} className="media-item-container card">
                  <div className="media-data-content">
                    <div className="content">
                      <p><strong><span className="icon"><i className="fas fa-align-left"></i></span> Caption:</strong> {media.caption}</p>
                      <p><strong><span className="icon"><i className="fas fa-photo-video"></i></span> Type:</strong> {media.mediaType}</p>
                      <p><strong>
                        <span className="icon has-text-danger">
                          <i className="fas fa-heart"></i>
                        </span> Likes: </strong>
                        {media.likeCount}
                      </p>
                      <p><strong><span className="icon"><i className="fas fa-comments"></i></span> Comments:</strong> {media.comments_count}</p>
                      <p><strong><span className="icon"><i className="fas fa-share"></i></span> Shares:</strong> {media.shareCount}</p>
                      <p><strong><span className="icon"><i className="fas fa-eye"></i></span> Video Views:</strong> {media.videoViews}</p>
                      <p><strong><span className="icon"><i className="fas fa-calendar-alt"></i></span> Published At:</strong> {new Date(media.publishedAt).toLocaleString()}</p>
                      <p><strong><span className="icon"><i className="fas fa-chart-bar"></i></span> Organic Impressions:</strong> {media.organicImpressions}</p>
                      <p><strong><span className="icon"><i className="fas fa-chart-bar"></i></span> Total Organic Reach:</strong> {media.totalOrganicReach}</p>
                      <p><strong><span className="icon"><i className="fas fa-link"></i></span> Permalink:</strong> <a href={media.permalink} target="_blank" rel="noopener noreferrer">View Media</a></p>
                      <p><strong><span className="icon"><i className="fas fa-hashtag"></i></span> Hashtags:</strong> {media.hashtags.join(', ')}</p>
                      <p><strong><span className="icon"><i className="fas fa-at"></i></span> Mentions:</strong> {media.mentions.join(', ')}</p>
                      <div>
                        <strong><span className="icon"><i className="fas fa-comment-dots"></i></span> Comments:</strong>
                        <ul>
                          {media.comments.map(comment => (
                            <li key={comment.id}>
                              {comment.username}: {comment.text} (Replies: {comment.reply_count})
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="media-image">
                    <figure className="image">
                      <img src={media.mediaUrl || 'default-media.png'} alt={media.caption} />
                    </figure>
                  </div>
                </div>
              ))
            ) : (
              <p>No media data available</p>
            )}
          </div>
        )
      } */}

        {activeSection === "Medias" && (
          <div className="section">
            <h4 className="title is-6">Media Section</h4>
            <div className="columns is-multiline">
              {mediaData.length > 0 ? (
                mediaData.map((media, index) => (
                  <div key={media.id} className="column is-half">
                    <div className="card">
                      <p>
                        <strong>Published At:</strong>{" "}
                        {new Date(media.publishedAt).toLocaleString()}
                      </p>
                      <figure className="image">
                        {/* <img
                          src={media.mediaUrl || "default-media.png"}
                          alt={media.caption}
                        /> */}
                        {media.mediaType === "VIDEO" ? (
                          <video controls>
                            <source src={media.mediaUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            src={media.mediaUrl || "default-media.png"}
                            alt={media.caption || "Media"}
                          />
                        )}
                      </figure>
                      <div className="card-content">
                        {/* <p><strong>Caption:</strong> {media.caption}</p> */}
                        {/* <p><strong>Type:</strong> {media.mediaType}</p> */}
                        <p>
                          <strong>
                            <span className="icon has-text-danger">
                              <i className="fas fa-heart"></i>
                            </span>{" "}
                          </strong>
                          {media.likeCount}

                          <strong style={{ marginLeft: "30px" }}>
                            <span
                              className="icon"
                              onClick={() => handleCommentClick(media.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <i className="fa fa-comment"></i>
                            </span>
                            <span> {media.comments_count}</span>
                          </strong>

                          {activeMediaId === media.id && (
                            <div>
                              <strong>Comments:</strong>
                              <ul>
                                {media.comments.map((comment) => (
                                  <li key={comment.id}>
                                    {comment.username}: {comment.text} (Replies:{" "}
                                    {comment.reply_count})
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </p>
                        {/* <p><strong>Shares:</strong> {media.shareCount}</p> */}
                        {/* <p><strong>Video Views:</strong> {media.videoViews}</p> */}

                        {/* <p><strong>Organic Impressions:</strong> {media.organicImpressions}</p> */}
                        {/* <p><strong>Total Organic Reach:</strong> {media.totalOrganicReach}</p> */}
                        {/* <p><strong>Permalink:</strong> <a href={media.permalink} target="_blank" rel="noopener noreferrer">View Media</a></p> */}
                        {/* <p><strong>Hashtags:</strong> {media.hashtags.join(', ')}</p> */}
                        {/* <p><strong>Mentions:</strong> {media.mentions.join(', ')}</p> */}
                        {/* <div>
                          <strong>Comments:</strong>
                          <ul>
                            {media.comments.map(comment => (
                              <li key={comment.id}>
                                {comment.username}: {comment.text} (Replies: {comment.reply_count})
                              </li>
                            ))}
                          </ul>
                        </div> */}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No media data available</p>
              )}
            </div>
          </div>
        )}

        {activeSection === "Followers_Demographic" && (
          <div className="section">
            <h4 className="title is-6">Followers Demographic</h4>
            {demographicData.length > 0 ? (
              <DemographicPieChart data={demographicData} />
            ) : (
              <p>No demographic data available</p>
            )}
          </div>
        )}
        {activeSection === "Followers_Age_Range" && (
          <div className="section">
            <h4 className="title is-6">Followers Age Range</h4>
            {ageRangeData.length > 0 ? (
              <AgeRangeLineChart data={ageRangeData} />
            ) : (
              <p>No age range data available</p>
            )}
          </div>
        )}
        {activeSection === "Country_Code" && (
          <div className="section">
            <h4 className="title is-6">Country Code Distribution</h4>
            {countryCodeData?.length > 0 ? (
              <CountryCodeBarChart data={countryCodeData} />
            ) : (
              <p>No country code data available</p>
            )}
          </div>
        )}
        {activeSection === "City_Demographic" && (
          <div className="section">
            <h4 className="title is-6">City Demographic</h4>
            {cityDemographicData.length > 0 ? (
              <CityDemographicBarChart data={cityDemographicData} />
            ) : (
              <p>No city demographic data available</p>
            )}
          </div>
        )}
        {activeSection === "Audience_Data" && (
          <AudienceDataCharts
            demographicData={demographicData}
            ageRangeData={ageRangeData}
            countryCodeData={countryCodeData}
          />
        )}
      </div>
      <Logout />
      {/* Consent Modal */}
      {window.location.hash === "#_=_" && (
        <ConsentModal
          show={showConsentModal}
          onClose={handleDecline}
          onConsent={handleConsent}
        />
      )}
    </>
  );
};

export default UserDetailPage;
